<template>
  <div class="mobile-top-container">
    <div class="mobile-top-menu">
      <div class="mobile-top-menu-left">
        <img
          v-if="!openMenu"
          src="../../assets/mobile-menu.svg"
          @click="openMenu = true"
          alt=""
        />
        <img
          v-else
          src="../../assets/mobile-menu-close.svg"
          @click="openMenu = false"
          alt=""
        />
      </div>
      <div class="mobile-top-menu-right" @click="openLang = !openLang">
        <img src="../../assets/mobile-language.svg" alt="" />
      </div>
    </div>
    <div class="router-box" :class="openMenu ? 'open' : ''">
      <div class="router-box-item" @click="routerChange('/index')">
        {{ $t("menu_index") }}
      </div>
      <div class="router-box-item" @click="routerChange('/about')">
        {{ $t("menu_about") }}
      </div>
      <div class="router-box-item" @click="routerChange('/team')">
        {{ $t("menu_team") }}
      </div>
      <div class="router-box-item" @click="routerChange('/business')">
        {{ $t("menu_business") }}
      </div>
      <div class="router-box-item" @click="routerChange('/case')">
        {{ $t("menu_case") }}
      </div>
      <div class="router-box-item" @click="routerChange('/news')">
          {{ $t("menu_info") }}
        </div>
      <div class="router-box-item" @click="routerChange('/success-case')">
        {{ $t("menu_success") }}
      </div>
    </div>
    <div class="language-box" :class="openLang ? 'open-lang' : ''">
      <div
        class="language-box-item"
        v-for="item in languageList"
        :key="item.key"
        @click="changeLanguage(item.key)"
        :class="$i18n.locale == item.key ? 'active' : ''"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openMenu: false,
      openLang: false,
      languageList: [],
    };
  },
  async created() {
    await this.getLanguage();
  },
  methods: {
    routerChange(url) {
      this.$router.replace(url);
      this.openMenu = false;
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.setLanguage(language);
      this.openLang = false;
    },
    async getLanguage() {
      const res = await this.$api.getLanguage();
      if (res.code == 200) {
        this.languageList = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .mobile-top-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    .mobile-top-menu {
      width: 100%;
      left: 0px;
      height: 80px;
      background: #26303c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 10px;
    }
    .router-box {
      background: #26303c;
      z-index: 99999;
      position: absolute;
      width: 80%;
      left: -100%;
      transition: 0.4s;
      opacity: 0;
      &-item {
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        padding: 0 5px;
      }
    }
    .open {
      left: 0;
      transition: 0.4s;
      opacity: 1;
    }
    .language-box {
      background: #26303c;
      z-index: 99999;
      position: absolute;
      width: 80%;
      right: -100%;
      transition: 0.4s;
      opacity: 0;
      display: block;
      &-item {
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        padding: 0 5px;
      }
      .active {
        color: #f88505;
      }
    }
    .open-lang {
      right: 0;
      opacity: 1;
      transition: 0.4s;
      display: inline-block;
    }
  }
}
</style>
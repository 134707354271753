import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
      path: '/index',
      name: 'Home',
      component: () => import('@/views/index')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/about')
    },
    {
      path: '/team',
      name: 'Team',
      component: () => import('@/views/team')
    },
    {
      path: '/teaminfo',
      name: 'TeamInfo',
      component: () => import('@/views/team-info')
    },
    {
      path: '/business',
      name: 'Business',
      component: () => import('@/views/business')
    },
    {
      path: '/case',
      name: 'Case',
      component: () => import('@/views/case')
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/information')
    },
    {
      path: '/info',
      name: 'Info',
      component: () => import('@/views/information-detail')
    },
    {
      path: '/success-case',
      name: 'SuccessCase',
      component: () => import('@/views/success-case')
    }]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="layout-container">
    <div class="top">
      <TopMenu v-if="screenWidth > 600" />
      <MobileTop v-else />
    </div>
    <div class="main">
      <router-view />
    </div>
    <div class="bottom">
      <BottomMenu />
    </div>
    <div class="service-btn" @click="goLink">
      <img src="../../assets/service.svg" alt="" />
    </div>
    <el-dialog
      :title="$t('home_service')"
      :visible.sync="dialogVisible"
      :width="screenWidth > 600 ? '30%' : '70%'"
    >
      <div class="dialog-service-btn" @click="goLink">
        {{ $t("home_service") }}
      </div>
      <div
        class="dialog-service-btn"
        :center="true"
        v-clipboard:copy="serviceInfo.telegram"
        v-clipboard:success="onCopy"
        v-if="serviceInfo.telegram"
      >
        Telegram {{ serviceInfo.telegram }}
      </div>
      <div
        class="dialog-service-btn"
        v-clipboard:copy="serviceInfo.email"
        v-clipboard:success="onCopy"
        v-if="serviceInfo.email"
      >
        Email {{ serviceInfo.email }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TopMenu from "@/components/TopMenu";
import MobileTop from "@/components/MobileTop";
import BottomMenu from "@/components/BottomMenu";

export default {
  components: {
    TopMenu,
    BottomMenu,
    MobileTop,
  },
  data() {
    return {
      dialogVisible: false,
      timer: false,
      screenWidth: document.body.clientWidth,
      serviceInfo: "",
    };
  },
  watch: {
    /*监听页面*/
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器

      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        setTimeout(() => {
          this.timer = false;
        }, 600);
      }
    },
  },
  async created() {
    await this.getService();
  },
  mounted() {
    window.onresize = () => {
      return () => {
        widnow.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
      };
    };
  },
  methods: {
    onCopy() {
      this.$Toast("copy success");
    },
    goLink() {
      window.open(this.serviceInfo.url, '_blank');
    },
    async getService() {
      const res = await this.$api.getService();
      if (res.code == 200) {
        this.serviceInfo = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  padding-top: 180px;
  .service-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
    cursor: pointer;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }
  .dialog-service-btn {
    background: #26303c;
    color: #fff;
    border-radius: 50px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  .layout-container {
    padding-top: 80px;
    .top {
      width: 100%;
    }
  }
  .service-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }
  }
  .dialog-service-btn {
    background: #26303c;
    color: #fff;
    border-radius: 50px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }
}
</style>